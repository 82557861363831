/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Alert, Link, Snackbar } from '@mui/material';

import { format } from 'date-fns';
import StepOne from '../components/Documents/Imports/StepOne';
import StepTwo from '../components/Documents/Imports/StepTwo';
import StepThree from '../components/Documents/Imports/StepThree';
import StepFour from '../components/Documents/Imports/StepFour';
import Stepper from '../components/Utilities/StepUI/Stepper';
import Header from '../components/ui/Header';
import StatusButton, { StatusTypes } from '../components/ui/StatusButton';
import CoversheetPrint from '../components/Documents/Imports/CoversheetPrint';
import VerificationErrorCoversheet from '../components/Documents/Imports/VerificationErrorCoversheet';
import { DocumentType } from '../globalTypes/objects';
import { apiFetch, apiPost } from '../adalConfig';
import getDocStepData from '../components/Documents/Imports/docStepData';
import HeaderButton from '../components/Utilities/StepUI/HeaderButton';
import StepPanel from '../components/Utilities/StepUI/StepPanel';
import ImportDocsHeader from '../components/Imports/ImportDocsHeader';
import { IconType } from '../components/ui/Icon';
import { convertToBase64 } from '../Utils';
import Panel, { PanelHeader } from '../components/ui/Panel';
import { ClientsContext } from '../ClientsContext';
import { DocumentTypesContext } from '../DocumentTypesContext';

const ColumnHeader = styled.span`
  font-weight: bold;
`;
const RowItem = styled.span`
  padding-top: 4;
`;

const getStepComponents = (
  { Step1, Step2, Step3, Step4 },
  submitted,
  digital,
  flaggedDoc,
  getInvestor,
  investor,
  onSubmit,
  submitStatus,
) => [
  {
    component: StepOne,
    value: 1,
    buttonText: 'next',
    data: !isEmpty(Step1) && [Step1.client?.name, Step1.docType?.label],
    props: {
      client: Step1?.client,
      docStatus: Step1?.docStatus,
      docType: Step1?.docType?.label,
      file: Step1.file,
      filename: Step1.file?.name,
      digital,
      onSubmit,
      submitStatus,
      isCopy: Step1?.isCopy,
    },
    validate: (digital && !!Step1.file) || !!Step1.docType,
  },
  {
    component: StepTwo,
    value: 2,
    buttonText: 'next',
    data: !!Step2?.loan && [
      ...(!isEmpty(Step2.loan)
        ? [
            Step2.loan?.propertyAddress,
            `${Step2.loan?.city}, ${Step2.loan?.state} ${Step2.loan?.zip}`,
          ]
        : []),
      ...(Step2.notFound ? ['Not found'] : []),
    ],
    props: {
      gotLoan: loan => (loan.investorID ? getInvestor(loan.investorID) : null),
      client: Step1.client,
      loan: Step2?.loan,
      notFound: Step2?.notFound,
      digital,
      docType: Step1.docType,
      allowMultipleDocs: doesAllowMultipleDocs(Step1.docType),
      isCorrection: Step2?.isCorrection,
      identifyingNumber: Step2?.identifyingNumber,
      investor,
    },
    validate: !!Step2?.loan,
  },
  {
    component: StepThree,
    value: 3,
    buttonText: 'next',
    data: Step3.valid && [],
    props: {
      loan: Step2.loan,
      docType: Step1.docType?.label,
      notFound: Step2.notFound,
      digital,
      flaggedDoc,
      investor,
      verificationFailures: Step3.data.verificationFailures,
      note: Step3.data.note,
    },
    validate: Step3.valid,
  },
  {
    component: StepFour,
    value: 4,
    buttonText: digital ? 'submit' : 'submit and print',
    data: null,
    props: {
      loan: Step2.loan,
      loanId: Step2?.loan?.id,
      docType: Step1.docType?.label,
      docTypeId: Step1.docType?.value,
      notFound: Step2.notFound,
      client: Step1.client,
      digital,
      flaggedDoc,
      investor,
    },
    validate: Step4.auditDeferred || Step4.audit?.passed || Step4.audit?.failureReasons?.length > 0,
  },
];

const doesAllowMultipleDocs = doc => {
  const multipleDocsRecording = [
    DocumentType.AffidavitOfCorrection,
    DocumentType.Assignment,
    DocumentType.CORR,
    DocumentType.Misc,
    DocumentType.POA,
    DocumentType.UCC3,
  ];
  const multipleDocsTitle = [DocumentType.Endorsement];
  const allowMultiple =
    (multipleDocsRecording.includes(doc?.value) && 'Recording Number') ||
    (multipleDocsTitle.includes(doc?.value) && 'Title Number');
  return allowMultiple;
};

const getInitialState = (digital, client) => ({
  currentStep: 1,
  formData: {
    Step1: { ...{ client: client || {} } },
    Step2: {
      ...{ client: client || {} },
    },
    Step3: { valid: false, data: {} },
    Step4: { valid: false, data: {} },
  },
  coversheetData: {},
  nextButtonStatus: StatusTypes.initial,
  submitted: false,
  digital,
  investor: {},
  recentlyUploadedDocs: [],
});

const getFlaggedDocData = ({ Step1, Step2, Step3 }) => ({
  clientId: Step1?.client?.id,
  documentType: Step1?.docType?.value,
  loanNumber: Step2?.notFound ? Step3?.data?.loanNumber : Step2?.loan?.loanNumber,
});

const checkDocIsFlagged = data => apiFetch(`/api/documents/GetFlaggedDoc`, { params: data });

const offset = new Date().getTimezoneOffset();

export default class ImportDocs extends React.Component {
  isDigital = this.props.location.pathname.includes('digital');

  state = getInitialState(this.isDigital);

  async getRecentDocuments() {
    const { data } = await apiFetch('/api/documents/GetRecentlyUploadedDocuments');
    data.sort((a, b) => (a.id < b.id ? 1 : -1));
    this.setState({ recentlyUploadedDocs: data });
  }

  async componentDidMount() {
    await this.getRecentDocuments();
    // this.setState({
    //   currentStep: 4,
    //   formData: {
    //     Step1: {
    //       client: {
    //         name: 'Demo Lender LLC',
    //         id: 2,
    //       },
    //       docType: {
    //         label: 'Mortgage',
    //         value: 12,
    //       },
    //     },
    //     Step2: {
    //       client: {
    //         name: 'Demo Lender LLC',
    //         id: 2,
    //       },
    //       loan: {},
    //       notFound: true,
    //       duplicate: false,
    //       identifyingNumber: '',
    //     },
    //     Step3: {
    //       valid: true,
    //       data: {
    //         loanNumber: '11111',
    //         dateDocumentsDrawn: '2022-08-02T04:00:00.000Z',
    //         propertyStreet: ' ',
    //         propertyCity: '',
    //         propertyState: '',
    //         propertyZip: '',
    //       },
    //     },
    //     Step4: {
    //       valid: false,
    //       data: {},
    //       audit: {
    //         passed: false,
    //         failureReasons: [],
    //         notes: '',
    //       },
    //       auditDeferred: false,
    //     },
    //   },
    //   submitted: false,
    //   flaggedDoc: '',
    //   investor: {},
    //   nextButtonStatus: 'INITIAL',
    // });
  }

  getInvestor = async id => {
    const { data: investor } = await apiFetch(`/api/investors/getInvestorById?investorId=${id}`);
    this.setState(prev => ({ ...prev, investor }));
  };

  handleChange = stepData => {
    const { currentStep } = this.state;

    const originalStepData = this.state.formData[`Step${currentStep}`];
    this.setState(
      {
        formData: {
          ...this.state.formData,
          [`Step${currentStep}`]: { ...originalStepData, ...stepData },
        },
      },
      () => {
        const { clientId, documentType, loanNumber } = getFlaggedDocData(this.state.formData);
        if (clientId && documentType && loanNumber) {
          checkDocIsFlagged({ clientId, documentType, loanNumber }).then(({ data: flaggedDoc }) =>
            this.setState({ flaggedDoc }),
          );
        }
      },
    );
  };

  // #region submit coversheet functions

  getDocSubmitData = async () => {
    const digital = this.props.location.pathname.includes('digital');
    const { Step1, Step2, Step3, Step4 } = this.state.formData;
    const client = Step1.client;
    return {
      auditDeferred: Step4.auditDeferred,
      audit: Step4.audit,
      documentType: Step1.docType.value,
      loanId: Step2.loan?.id,
      ...(Step2.notFound ? Step3.data : {}),
      clientName: client.name,
      ...(digital && {
        filename: Step1.file?.name,
        fileContents: await convertToBase64(Step1.file),
        clientId: client.id,
      }),
      ...(Step2.identifyingNumber && { countyRecordingNumber: Step2.identifyingNumber }),
      clientId: client.id,
      isCorrection: Step2.isCorrection,
      isCopy: Step1.isCopy,
    };
  };

  submitPhysicalDocument = async docData => {
    const { data } = await apiPost('/api/documents/coversheet', docData);
    this.setState({ coversheetData: data });
  };

  submitDigitalDoc = async docData => {
    try {
      const { data } = await apiPost('/api/documents/createSoftCopy', docData);
      if (data && data.fileSubpath !== null) {
        try {
          await apiPost('/api/documents/checkIfDocAlreadyExists', data).then(result => {
            if (result.data) {
              this.setState({ uploadStatus: StatusTypes.warning });
            } else {
              this.setState({ uploadStatus: StatusTypes.success });
            }
          });
        } catch (e) {
          this.setState({ uploadStatus: StatusTypes.success });
        }
      } else {
        this.setState({ uploadStatus: StatusTypes.error });
      }
    } catch (e) {
      this.setState({ uploadStatus: StatusTypes.error, errorMsg: e });
    }
  };

  getFailedVerificationInfo = docId => {
    const { Step3 } = this.state.formData;
    return {
      documentId: docId,
      note: Step3.data.note,
      verificationFailures: Step3.data.verificationFailures,
    };
  };

  submitFailedVerification = async docId => {
    const submitInfo = this.getFailedVerificationInfo(docId);
    try {
      await apiPost('/api/documents/failVerification', submitInfo);
    } catch (e) {
      if (e.response) {
        if (e.response.data?.errors) {
          alert(e.response.data.errors.map(error => error.message).join('\n'));
        } else {
          const errorMessage = e.response.data.split('\n')[0];
          alert(errorMessage || e.message);
        }
      } else {
        alert(e.message);
      }
    }
  };

  getFailedVerificationCoversheetInfo = async docId => {
    const { data } = await apiFetch(`/api/documents/failVerificationCoversheet?docId=${docId}`);
    this.setState({ coversheetData: data });
  };

  // #endregion

  onSubmit = async () => {
    this.setState({ nextButtonStatus: StatusTypes.loading });

    const { Step2, Step3 } = this.state.formData;

    const { submitted } = this.state;
    const digital = this.props.location.pathname.includes('digital');
    const failed = !Step2.notFound && Step3.data.verificationFailures?.length > 0;

    const docData = await this.getDocSubmitData();

    try {
      if (digital) {
        await this.submitDigitalDoc(docData);
        this.setState({ submitted: true, nextButtonStatus: StatusTypes.success });
        await this.getRecentDocuments();
      } else {
        if (!submitted) {
          await this.submitPhysicalDocument(docData);
          if (failed) {
            await this.submitFailedVerification(this.state.coversheetData.documentId);
            await this.getFailedVerificationCoversheetInfo(this.state.coversheetData.documentId);
          }
        }
        window.print(); // can't clear or set to success because don't know if user clicked print or cancel
        this.setState({ submitted: true, nextButtonStatus: StatusTypes.initial });
      }
    } catch (error) {
      this.setState({ nextButtonStatus: StatusTypes.error });
      throw new Error(error);
    }
  };

  handleButtonClick = currentStep => {
    if (currentStep < 4) {
      this.setState({ currentStep: currentStep + 1 });
    } else {
      this.onSubmit();
    }
  };

  CurrentStepComponent = () => {
    const { currentStep, formData, submitted, flaggedDoc, investor, nextButtonStatus } = this.state;
    const digital = this.isDigital;
    return getStepComponents(
      formData,
      submitted,
      digital,
      flaggedDoc,
      this.getInvestor,
      investor,
      this.onSubmit,
      nextButtonStatus,
    ).find(step => step.value === currentStep);
  };

  DocStepper = () => {
    const { currentStep, submitted, formData, investor, nextButtonStatus } = this.state;
    const digital = this.isDigital;
    return (
      <Stepper
        currentStep={currentStep}
        onEditStep={stepNumber => this.setState({ currentStep: stepNumber })}
        submitted={submitted}
        stepData={getDocStepData(
          getStepComponents(
            formData,
            submitted,
            digital,
            this.getInvestor,
            investor,
            this.onSubmit,
            nextButtonStatus,
          ).map(step => step.data),
        )}
      />
    );
  };

  StepComponent = () => {
    const { formData, submitted, digital } = this.state;
    const CurrentStepComponent = this.CurrentStepComponent();
    return (
      <ClientsContext.Consumer>
        {clients => (
          <DocumentTypesContext.Consumer>
            {({ docTypes: documentTypes }) => (
              <CurrentStepComponent.component
                handleChange={data => this.handleChange(data)}
                disabled={submitted}
                {...CurrentStepComponent.props}
                clients={clients}
                documentTypes={documentTypes}
                handleDocTypeChange={() =>
                  this.setState({
                    formData: {
                      ...formData,
                      Step2: { ...formData.Step2, isCorrection: false, identifyingNumber: '' },
                    },
                  })
                } // for step one when doc type changes to not allow correction or need recording number
                handleClientChange={() =>
                  this.setState({
                    ...this.state,
                    ...getInitialState(digital, formData.Step1?.client),
                  })
                } // for step one when client changes to clear information
              />
            )}
          </DocumentTypesContext.Consumer>
        )}
      </ClientsContext.Consumer>
    );
  };

  StepButton = () => {
    const { nextButtonStatus, currentStep, submitted } = this.state;
    const CurrentStepComponent = this.CurrentStepComponent();
    return !this.isDigital ? (
      <StatusButton
        text={CurrentStepComponent.buttonText}
        disabled={!CurrentStepComponent.validate || submitted}
        initialIcon={IconType.RightArrow}
        status={nextButtonStatus}
        onClick={() => this.handleButtonClick(currentStep)}
      />
    ) : null;
  };

  render() {
    const { formData, coversheetData, digital, uploadStatus, errorMsg, recentlyUploadedDocs } =
      this.state;
    const failVerif =
      !formData.Step2.notFound && formData.Step3.data.verificationFailures?.length > 0;

    const trimmedClientName = d => {
      return <>{d.client.replace(/(\w+(?: \w+)?).*/, '$1')}</>;
    };

    return (
      <>
        <div css={{ '@media print': { display: 'none' } }}>
          <Header headerText="Import Docs">
            <HeaderButton
              onClick={() => this.setState(getInitialState(digital, formData.Step1.client))}
            />
          </Header>
          <ImportDocsHeader />
          {digital ? (
            <div css={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
              <StepPanel
                stepper={this.DocStepper}
                component={this.StepComponent}
                button={this.StepButton}
                showStepper={false}
              />
              <Panel styles={{ marginTop: 40, width: 475, maxHeight: 500 }}>
                <PanelHeader headerStyles={{ fontWeight: 'bold' }}>
                  Your Recently Uploaded Digital Documents
                </PanelHeader>
                <div
                  css={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 2fr',
                    padding: 8,
                  }}
                >
                  <ColumnHeader>Doc ID</ColumnHeader>
                  <ColumnHeader>Time</ColumnHeader>
                  <ColumnHeader>Client</ColumnHeader>
                </div>
                <div css={{ maxHeight: 350, overflowY: 'scroll' }}>
                  {recentlyUploadedDocs.map(d => (
                    <div
                      key={d.documentId}
                      css={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 2fr',
                        padding: 8,
                      }}
                    >
                      <RowItem>
                        <Link target="_blank" href={`/documents/${d.documentId}`}>
                          {d.documentId}
                        </Link>
                      </RowItem>
                      <RowItem>
                        {format(
                          new Date(d.createdAt).setMinutes(
                            new Date(d.createdAt).getMinutes() - offset,
                          ),
                          'h:mm a',
                        )}
                      </RowItem>
                      <RowItem>{trimmedClientName(d)}</RowItem>
                    </div>
                  ))}
                </div>
              </Panel>
            </div>
          ) : (
            <StepPanel
              stepper={this.DocStepper}
              component={this.StepComponent}
              button={this.StepButton}
              showStepper
            />
          )}
        </div>
        <div css={{ display: 'none', '@media print': { display: 'block' } }}>
          {!isEmpty(coversheetData) &&
            (!failVerif ? (
              <CoversheetPrint {...coversheetData} notFound={formData.Step2.notFound} />
            ) : (
              <VerificationErrorCoversheet {...coversheetData} />
            ))}
        </div>
        {uploadStatus === StatusTypes.success && (
          <SuccessMessage
            type="success"
            text="Document uploaded successfully."
            onClose={() => this.setState({ uploadStatus: StatusTypes.initial })}
          />
        )}
        {uploadStatus === StatusTypes.warning && (
          <SuccessMessage
            type="warning"
            text="An identical document already exists."
            onClose={() => this.setState({ uploadStatus: StatusTypes.initial })}
          />
        )}
        {uploadStatus === StatusTypes.error && (
          <SuccessMessage
            type="error"
            text={`Failed to upload document. ${errorMsg}`}
            onClose={() => this.setState({ uploadStatus: StatusTypes.initial, errorMsg: '' })}
          />
        )}
      </>
    );
  }
}

const SuccessMessage = ({ type, text, onClose }) => {
  return (
    <Snackbar open autoHideDuration={6000} onClose={onClose}>
      <Alert severity={type} variant="filled">
        <span css={{ textAlign: 'center' }}>{text}</span>
      </Alert>
    </Snackbar>
  );
};
