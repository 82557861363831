/* eslint-disable react-hooks/exhaustive-deps */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { match, useHistory } from 'react-router';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { isEmpty } from 'lodash';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { apiFetch } from '../adalConfig';
import LoanDocuments from '../components/Loans/LoanDocuments';
import LoanInformation from '../components/Loans/LoanInformation';
import { LoanInformation as LoanInformationType } from '../components/Loans/types';
import Header from '../components/ui/Header';
import { CopyIcon } from '../components/ui/icons';
import CoversheetPrint from '../components/Documents/Imports/CoversheetPrint';
import VerificationErrorCoversheet, {
  VerificationErrorCoversheetProps,
} from '../components/Documents/Imports/VerificationErrorCoversheet';
import { useToaster } from '../Hooks/toasters';
import DeletedDocuments from './LoanDocuments/DeletedDocuments';
import LoanMatches from './LoanDocuments/LoanMatches';
import { Loan } from '../globalTypes/objects';
import { DispositionsProvider } from '../components/CallCenter/DispositionsContext';

export const LoanContext = React.createContext({} as LoanContext);

type LoanContext = {
  getLoanData: () => void;
};

type Props = {
  match: match<{ loanId: string }>;
};

const headerStyles = {
  display: 'flex',
  alignItems: 'center',
  div: { display: 'flex', alignItems: 'center', fontSize: 14 },
  '> div:first-of-type': {
    fontWeight: 400,
    paddingRight: 12,
  },
  svg: { marginRight: 12, marginTop: 2 },
};

const copyStyles = {
  backgroundColor: 'hsl(120, 100%, 93%)',
  border: '1px solid transparent',
  borderRadius: 5,
  justifyContent: 'center',
  opacity: 0,
  overflow: 'hidden',
  transition: 'all .13s',
  whiteSpace: 'nowrap',
  width: 0,
};

const copiedStyles = {
  border: '1px solid #00C642',
  padding: '4px 0px',
  opacity: 1,
  width: 130,
};

const useStyles = makeStyles(() =>
  createStyles({
    tabs: {
      marginTop: '64px',
      '& span': { fontWeight: 600 },
    },
    indicator: {
      backgroundColor: 'blue',
    },
  }),
);

const getLoanData = async (loanId: string) => {
  const { data } = await apiFetch<LoanInformationType>(`/api/loans/${loanId}/information`);

  return data;
};

const getLinkedLoan = async (loanId: string) => {
  const { data } = await apiFetch<Loan>(`/api/loans/get-linked-loan/${loanId}`);

  return data;
};

const ClientSingleLoan = ({ match }: Props) => {
  const [tab, setTab] = useState(0);
  const [loanInformation, setLoanInformation] = useState<LoanInformationType>({
    id: 0,
    clientId: 0,
    client: '',
    clientAccountRep: '',
    appNumber: '',
    borrower: '',
    city: '',
    county: '',
    titleCompanyContactNumber: '',
    titleCompanyContactEmail: '',
    sendToGlobal: false,
    date: '',
    dateFunded: '',
    investorNum: '',
    loanAmount: 0,
    titleCompany: '',
    titleCompanySendToGlobal: false,
    investor: '',
    paymentStream: '',
    propertyType: '',
    notesData: [{ createdAt: '', id: 0, modifiedBy: '', note: '', isInternal: false }],
    documentNotes: [{ createdAt: '', id: 0, modifiedBy: '', note: '' }],
    propertyAddress: '',
    requiresOriginalDocument: false,
    state: '',
    mtaNum: '',
    userName: '',
    zip: '',
    deferUntil: '',
    requiredDocuments: [],
  });
  const [coversheetData, setCoversheetData] = useState<VerificationErrorCoversheetProps>(
    {} as VerificationErrorCoversheetProps,
  );
  const [copySuccess, setCopySuccess] = useState('');
  const [deletedDocsModal, setDeletedDocsModal] = useState(false);
  const [documentMatchesModal, setDocumentMatchesModal] = useState(false);
  const [linkedLoan, setLinkedLoan] = useState<Loan | null>(null);

  const updateLoanInformation = async () => {
    try {
      const data = await getLoanData(match.params.loanId);
      setLoanInformation(data);
    } catch (e) {
      if (e.response) {
        const errorMessage = e.response.data.split('\n')[0];
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }

      history.push('/400');
    }
  };

  useEffect(() => {
    updateLoanInformation();

    getLinkedLoan(match.params.loanId).then(setLinkedLoan);
  }, [match.params.loanId]);

  const classes = useStyles();
  const textAreaRef = useRef(null);
  const { errorToaster } = useToaster();
  const history = useHistory();

  async function copyToClipboard(e) {
    await navigator.clipboard.writeText(
      `${loanInformation.client} - ${loanInformation.appNumber} - ${loanInformation.borrower}`,
    );
    setCopySuccess('Loan Info Copied');

    setTimeout(() => {
      setCopySuccess('');
    }, 2000);
  }

  return (
    <Fragment>
      <div css={{ '@media print': { display: 'none' } }}>
        <Header styles={headerStyles} headerText="" fixed>
          <div className="df aic">
            <Breadcrumbs
              aria-label="breadcrumb"
              separator="-"
              css={{
                li: { fontWeight: 'bold', color: '#000' },
              }}
              ref={textAreaRef}
            >
              <div>{loanInformation.client}</div>
              <div>{loanInformation.appNumber}</div>
              <div>{loanInformation.borrower}</div>
            </Breadcrumbs>
            {document.queryCommandSupported('copy') && (
              <div className="ml2">
                <CopyIcon
                  onClick={copyToClipboard}
                  css={{ maxWidth: 18, cursor: 'pointer', '&:hover': { g: { fill: 'blue' } } }}
                />
                <div css={[copyStyles, copySuccess && copiedStyles]}>{copySuccess}</div>
              </div>
            )}
          </div>
        </Header>
        <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Tabs
            classes={{ root: classes.tabs }}
            indicatorColor="primary"
            value={tab}
            textColor="primary"
            onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
              setTab(newValue);
            }}
          >
            {['INFORMATION', 'DOCUMENTS'].map((tabLabel, i) => (
              <Tab key={tabLabel} label={tabLabel} id={`tabpanel-${i}`} />
            ))}
          </Tabs>
          {tab === 1 && (
            <div
              css={{
                display: 'flex',
                justifyContent: 'right',
                marginTop: '64px',
                paddingRight: '75px',
              }}
            >
              <div>
                <Button
                  color="primary"
                  disabled={!linkedLoan}
                  onClick={() => window.open(`/loans/${linkedLoan?.id}`)}
                >
                  First/Second Mortgage
                </Button>
              </div>
              <div>
                <Button color="primary" onClick={() => setDeletedDocsModal(true)}>
                  Deleted Documents
                </Button>
              </div>
              <div>
                <Button color="primary" onClick={() => setDocumentMatchesModal(true)}>
                  Suggested Matches
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className="p3">
          <TabPanel value={tab} index={0}>
            <LoanContext.Provider value={{ getLoanData: updateLoanInformation }}>
              <DispositionsProvider>
                <LoanInformation originalLoanInformation={loanInformation} />
              </DispositionsProvider>
            </LoanContext.Provider>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <LoanDocuments
              loan={loanInformation}
              setCoversheetData={setCoversheetData}
              coversheetData={coversheetData}
            />
          </TabPanel>
        </div>
      </div>
      <Modal
        css={{ marginLeft: '250px', marginTop: '300px' }}
        open={deletedDocsModal}
        onClose={() => setDeletedDocsModal(false)}
      >
        <DeletedDocuments loanId={loanInformation.id} />
      </Modal>
      <Modal
        css={{ marginLeft: '250px', marginTop: '300px', width: '1400px' }}
        open={documentMatchesModal}
        onClose={() => setDocumentMatchesModal(false)}
      >
        <LoanMatches loanId={loanInformation.id} />
      </Modal>
      {!isEmpty(coversheetData) && (
        <div css={{ display: 'none', '@media print': { display: 'block' } }}>
          {!coversheetData.verificationFailures.length ? (
            <CoversheetPrint {...coversheetData} />
          ) : (
            <VerificationErrorCoversheet {...coversheetData} />
          )}
        </div>
      )}
    </Fragment>
  );
};

export default ClientSingleLoan;

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;

  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};
