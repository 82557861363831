/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useContext, useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import { Autocomplete, Button } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import { apiPost } from '../adalConfig';
import Header from '../components/ui/Header';
import { useClients } from '../Hooks/useClients';
import { DocumentTypeProperties, DocumentTypesContext } from '../DocumentTypesContext';
import { Client } from '../globalTypes/objects';
import { useToaster } from '../Hooks/toasters';
import PrePrintedCoversheet from '../components/Documents/PrePrintedCoversheet';

const useStyles = makeStyles(theme => ({
  paperStyles: {
    width: 800,
    margin: 40,
    padding: 24,
  },
  docTypeTableCell: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
}));

export default () => {
  const { docTypes: documentTypes } = useContext(DocumentTypesContext);

  const { errorToaster } = useToaster();

  const [selectedClient, setSelectedClient] = useState<Client>();
  const [selectedDocumentType, setSelectedDocumentType] = useState<DocumentTypeProperties>(
    documentTypes.find(dt => dt.label === 'Mortgage') || ({} as DocumentTypeProperties),
  );
  const [numberOfPages, setNumberOfPages] = useState<number>(1);
  const [newIds, setNewIds] = useState<number[]>([]);

  const clients = useClients();

  const canSubmit = useMemo(
    () => !!selectedClient?.id && !!selectedDocumentType.value && numberOfPages > 0,
    [selectedClient?.id, selectedDocumentType.value, numberOfPages],
  );

  const handleDocTypeClick = (documentType: string) => {
    const matchingDocumentType = documentTypes.find(t => t.label === documentType);
    if (matchingDocumentType === undefined) {
      return;
    }

    setSelectedDocumentType(matchingDocumentType);
  };

  const onSubmitClick = async () => {
    if (!canSubmit) {
      errorToaster('All fields are required');
      return;
    }

    const { data: ids } = await apiPost<number[]>(
      '/api/documents/generateBlankDocumentsForClient',
      {
        clientId: selectedClient?.id,
        documentType: selectedDocumentType.value,
        amount: numberOfPages,
      },
    );

    setNewIds(ids);
    window.print();
  };
  const classes = useStyles();
  return (
    <Fragment>
      <div css={{ '@media print': { display: 'none' } }}>
        <Header headerText="Print Coversheets" />
        <Paper variant="outlined" className={classes.paperStyles}>
          <Autocomplete
            options={clients}
            getOptionLabel={(option: Client) => option?.company || ''}
            onChange={(event: any, newValue: Client | undefined) => setSelectedClient(newValue)}
            style={{ width: 300 }}
            autoHighlight
            renderInput={params => (
              <TextField {...params} label="Select Client" variant="outlined" />
            )}
          />
          <div className="mt2">
            <div>Select Document Type</div>
            <div className={classes.docTypeTableCell}>
              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"
                size="large"
                css={{ height: 46 }}
              >
                <Button
                  variant={selectedDocumentType.label === 'Mortgage' ? 'contained' : 'outlined'}
                  onClick={() => handleDocTypeClick('Mortgage')}
                >
                  Mortgage
                </Button>
                  <Button
                      variant={selectedDocumentType.label === 'Second Mortgage' ? 'contained' : 'outlined'}
                      onClick={() => handleDocTypeClick('Second Mortgage')}
                  >
                      Second Mortgage
                  </Button>
                <Button
                  variant={selectedDocumentType.label === 'Policy' ? 'contained' : 'outlined'}
                  onClick={() => handleDocTypeClick('Policy')}
                >
                  Policy
                </Button>
                <Button
                  variant={selectedDocumentType.label === 'Unknown' ? 'contained' : 'outlined'}
                  onClick={() => handleDocTypeClick('Unknown')}
                >
                  Other
                </Button>
              </ButtonGroup>
            </div>
          </div>
          <div className="mt3">
            <TextField
              label="Number of Coversheets"
              type="number"
              value={numberOfPages}
              onChange={e => {
                const number = +e.target.value;
                if (number <= 0) {
                  return;
                }
                setNumberOfPages(number);
              }}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="mt2">
            <Button
              onClick={onSubmitClick}
              variant="contained"
              color="primary"
              disabled={!canSubmit}
            >
              Submit
            </Button>
          </div>
        </Paper>
      </div>
      <div css={{ display: 'none', '@media print': { display: 'block' } }}>
        {newIds.map(i => (
          <PrePrintedCoversheet
            key={i}
            documentId={i}
            clientName={selectedClient?.company ?? ''}
            documentType={
              selectedDocumentType.label === 'Unknown' ? '' : selectedDocumentType.label
            }
          />
        ))}
      </div>
    </Fragment>
  );
};
