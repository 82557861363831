/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Column,
  SortingRule,
  useExpanded,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import {
  BooleanParam,
  NumberParam,
  ObjectParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { format, formatDistanceToNow, isThisWeek, isValid } from 'date-fns';
import { toDate } from 'date-fns-tz';
import makeStyles from '@mui/styles/makeStyles';
import {
  CircularProgress,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import GradingIcon from '@mui/icons-material/Grading';
import { CancelTokenSource } from 'axios';
import { stringifyUrl } from 'query-string';
import BroadMatchPanel from './BroadMatchPanel';
import { useClients } from '../../Hooks/useClients';
import usePageData from './hooks/useDocumentCatchApi';
import { DocumentDetails, ExtractedDocumentData } from './types/document';
import { downloadFile, getDocTypeName } from '../../Utils';
import colors from '../../styles/colors';
import {
  CheckIcon,
  ClientIcon,
  DownArrowIcon,
  ErrorsIcon,
  LeftArrowIcon,
  Processing,
  RightArrowIcon,
  RightCaretIcon,
  UpArrowIcon,
} from '../../components/ui/icons';
import Button from '../../components/ui/Button';

const useStyles = makeStyles({
  root: {
    margin: 30,
    marginTop: 10,
    width: '96%',
    maxHeight: 'calc(100vh - 50px)',
  },
  container: {
    maxHeight: 'calc(100vh - 150px)',
  },
  tableCell: {
    textAlign: 'center',
  },
  border: {
    borderTop: 'none',
    borderBottom: '1px solid lightgrey',
  },
  input: {
    width: 308,
    height: 36,
    padding: 10,
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    border: '1px solid #e5e5ea',
  },
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minHeight: '200px',
    maxHeight: '90%',
    overflow: 'auto',
    border: '2px solid #000',
  },
});
const matchTitles = {
  color: 'gray',
  paddingBottom: 8,
  paddingTop: 12,
};
const buttonWrap = {
  margin: '0 24px',
  height: 36,
};
const selectList = {
  margin: 10,
  marginLeft: 30,
  padding: 10,
  border: `1px solid ${colors.grayLight}`,
  borderRadius: 8,
  WebkitAppearance: 'none',
};
const hoverArrow = {
  padding: 16,
  borderRadius: '50%',
  transition: 'background-color 300ms ease',
  ':hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
  svg: {
    transition: 'all .2s',
  },
};
const rotate = {
  ' svg': {
    transform: 'rotate(90deg)',
  },
};
const progressCircle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
};
const arrow = {
  padding: 16,
};
const disabledArrow = {
  color: colors.grayLight,
};

const blackHoleFilterOptions = [
  'ALL DOCUMENTS',
  'NO OCR',
  'AGED 60 DAYS',
  'AGED 90 DAYS',
  'AGED 120 DAYS',
  'AGED 120 - 365 DAYS',
  'AGED 1 YEAR',
  'BROAD MATCH QUEUE',
  'MISSING DATE FUNDED',
  'MISSING EXTRACTED DATA',
  'MATCH SUGGESTION DIFFERENT CLIENT',
] as const;

const missingLoanFilterOptions = [
  'SHOW ALL',
  'HIDE MISSING LOAN',
  'SHOW ONLY MISSING LOAN',
  'LIKELY HAVE LOAN',
] as const;

const trueNotFoundFilterOptions = [
  'HIDE TRUE NOT FOUNDS',
  'SHOW ALL',
  'SHOW ONLY TRUE NOT FOUNDS',
] as const;

const excludesFilterOptions = ['SHOW ALL', 'EXCLUDE BULK SHIPPED'] as const;

export default function Index() {
  const clients = useClients();
  const [docId, setDocId] = useState<number>(0);
  const [extractedInfo, setExtractedInfo] = useState<ExtractedDocumentData | null>(
    {} as ExtractedDocumentData,
  );
  const [showBroadMatchesModal, setShowBroadMatchesModal] = useState<boolean>(false);

  const theColumns: Column<DocumentDetails>[] = [
    {
      Header: '',
      id: 'rowId',
      Cell: ({ row }) => (
        <span {...(row.original.matchSuggestions.length ? row.getToggleRowExpandedProps() : null)}>
          {row.original.matchSuggestions.length ? (
            <span css={[hoverArrow, row.isExpanded && rotate]}>
              <RightCaretIcon />
            </span>
          ) : null}
        </span>
      ),
    },
    {
      Header: 'Barcode',
      accessor: 'documentId',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ value }) => (
        <Link
          rel="noopener noreferrer"
          target="_blank"
          href={`/documents/${value}?performManualExtraction=1`}
        >
          {value}
        </Link>
      ),
    },
    {
      Header: 'Client',
      accessor: 'client',
      disableSortBy: true,
      id: 'client',
    },
    {
      Header: 'Created',
      id: 'documentCreatedAt',
      disableFilters: true,
      disableSortBy: true,
      accessor: originalRow => toDate(originalRow.documentCreatedAt, { timeZone: 'UTC' }),
      Cell: ({ value: date }) =>
        isThisWeek(date)
          ? formatDistanceToNow(date, { addSuffix: true })
          : format(date, 'MMMM do yyyy'),
    },
    {
      Header: 'Document Type',
      accessor: 'documentType',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ value }) => getDocTypeName(value),
    },
    {
      Header: 'Format',
      accessor: 'hardCopy',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ value }) => (value ? 'Physical' : 'Digital'),
    },
    {
      Header: 'OCR Performed',
      accessor: 'ocrStatus',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ value }) => {
        if (value === 'SUCCESS') {
          return <CheckIcon stroke={colors.green} />;
        }
        if (value === 'FAILURE') {
          return <ErrorsIcon stroke={colors.red} />;
        }
        return <Processing stroke={colors.blueLight} />;
      },
    },
    {
      id: 'dateFunded',
      Header: 'Date Funded (Extracted)',
      accessor: ({ extractedDocumentData }) =>
        extractedDocumentData?.map(d => d.dateFunded).filter(d => d) ?? [],
      Cell: ({ value }) => {
        const dates = value.map(val => new Date(val));
        const validDates = dates.filter(date => isValid(date));
        const minDate = new Date(Math.min(...validDates));
        return isValid(minDate) ? format(minDate, 'MMMM do yyyy') : '';
      },
    },
    {
      Header: '',
      accessor: 'extractedClient',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row }) =>
        row.original.extractedClient ? (
          <Tooltip title={row.original.extractedClient}>
            <IconButton>
              <ClientIcon />
            </IconButton>
          </Tooltip>
        ) : null,
    },
    {
      Header: '',
      accessor: 'broadMatches',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row }) =>
        row.original.broadMatches?.length ? (
          <Tooltip title="View Matches">
            <IconButton
              onClick={() => {
                setDocId(row.original.documentId);
                setExtractedInfo(
                  row.original.extractedDocumentData ? row.original.extractedDocumentData[0] : null,
                );
                setShowBroadMatchesModal(!showBroadMatchesModal);
              }}
            >
              <GradingIcon />
            </IconButton>
          </Tooltip>
        ) : null,
    },
    {
      Header: 'Black Hole',
      disableSortBy: true,
      id: 'blackHole',
    },
    {
      Header: 'Missing Loan',
      disableSortBy: true,
      id: 'missingLoan',
    },
    {
      Header: 'True Not Found',
      disableSortBy: true,
      id: 'trueNotFounds',
    },
    {
      Header: 'Exclusion',
      disableSortBy: true,
      id: 'exclusion',
    },
  ];
  const [searchString, setSearchString] = useState('');
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    pageSize: withDefault(NumberParam, 25),
    filter: withDefault(ObjectParam, {
      blackHole: 'NO OCR',
      client: '-1',
      missingLoan: 'SHOW ALL',
      trueNotFounds: 'HIDE TRUE NOT FOUNDS',
      exclusion: 'SHOW ALL',
    }),
    searchString: withDefault(StringParam, searchString),
    isExport: withDefault(BooleanParam, false),
    sortBy: {
      encode: (sortingByCols: SortingRule<string>[]) =>
        sortingByCols.map(col => [col.id, col.desc ? 1 : 0]).join('|'),
      decode: value =>
        typeof value === 'string' && value.length
          ? value
              .split('|')
              .map(a => a.split(','))
              .map(([id, desc]) => ({ id, desc: !!parseInt(desc) }))
          : [],
    },
  });
  const [xhr, setXhr] = useState<CancelTokenSource | undefined>(undefined);

  const [isLoading, setIsLoading] = useState(false);

  const xhrData = () => ({
    xhr,
    setXhr,
  });

  const documentsQuery = usePageData(
    query.page,
    query.pageSize,
    query.filter,
    query.searchString,
    query.isExport,
    query.sortBy,
    xhrData,
  );

  const refresh = () => {
    documentsQuery.refetch();
  };

  const { data, totalCount } = useMemo(
    () => (documentsQuery.isSuccess ? documentsQuery.data : { data: [], totalCount: 0 }),
    [documentsQuery.data, documentsQuery.isSuccess],
  );
  const exportUrl = stringifyUrl({
    url: '/Api/DocumentCatchAll',
    query: {
      page: query.page.toString(),
      blackHole: query.filter.blackHole?.toString(),
      client: query.filter.client?.toString(),
      missingLoan: query.filter.missingLoan?.toString(),
      trueNotFounds: query.filter.trueNotFounds?.toString(),
      exclusion: query.filter.exclusion?.toString(),
      searchString: query.searchString,
      isExport: true.toString(),
      sortBy: '[]',
    },
  });

  const exportData = async () => {
    try {
      setIsLoading(true);
      return await downloadFile(exportUrl, 'DocumentCatchall.csv');
    } finally {
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => theColumns, []);

  const filtersFromQueryString = useMemo(
    () => Object.entries(query.filter).map(([id, value]) => ({ id, value })),
    [query.filter],
  );

  const sortByMemo = useMemo(() => query.sortBy, [query.sortBy]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page: rows,
    prepareRow,
    nextPage,
    previousPage,
    canPreviousPage,
    setFilter,
    gotoPage,
    setPageSize,
    setSortBy,
    visibleColumns,
    state: { pageIndex, pageSize, filters, sortBy },
  } = useTable(
    {
      data,
      // @ts-ignore
      columns,
      initialState: {
        pageIndex: query.page - 1,
        pageSize: query.pageSize,
        filters: filtersFromQueryString,
        sortBy: sortByMemo,
        hiddenColumns: ['blackHole', 'missingLoan', 'trueNotFounds', 'exclusion'],
      },
      expandSubRows: false,
      manualPagination: true,
      manualFilters: true,
      manualSortBy: true,
      disableSortRemove: true,
      pageCount: totalCount <= 0 ? -1 : Math.ceil(totalCount / query.pageSize),
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
  );

  const filterValue = useCallback(
    (column: string) => filters.find(filter => filter.id === column)?.value,
    [filters],
  );

  // keep query string in sync with table state
  useEffect(() => {
    const filtersFormattedForQuery = Object.fromEntries(
      filters.map(({ id, value }) => [id, value]),
    );
    setQuery({
      page: pageIndex + 1,
      pageSize,
      filter: filtersFormattedForQuery,
      searchString,
      isExport: false,
      sortBy,
    });
  }, [filters, sortBy, pageIndex, pageSize, setQuery, searchString]);

  const classes = useStyles();
  const isAgedLoanFilter =
    filterValue('blackHole')?.startsWith('AGED') ||
    filterValue('blackHole') === 'MISSING DATE FUNDED';

  const isOcrFilter = filterValue('blackHole') === 'NO OCR';

  // @ts-ignore
  return (
    <Fragment>
      <div css={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <select
            // @ts-ignore
            css={selectList}
            value={filterValue('blackHole')}
            onChange={e => {
              gotoPage(0);
              setSortBy([]);
              setFilter('blackHole', e.target.value);
              if (
                e.target.value === 'MATCH SUGGESTION DIFFERENT CLIENT' ||
                e.target.value === 'BROAD MATCH QUEUE'
              ) {
                setFilter('trueNotFounds', 'SHOW ALL');
              }
            }}
          >
            {blackHoleFilterOptions.map(filter => (
              <option key={filter} value={filter}>
                {filter}
              </option>
            ))}
          </select>
          {isAgedLoanFilter && (
            <select
              css={selectList}
              value={filterValue('missingLoan')}
              onChange={e => {
                gotoPage(0);
                setSortBy([]);
                setFilter('missingLoan', e.target.value);
              }}
            >
              {missingLoanFilterOptions.map(filter => (
                <option key={filter} value={filter}>
                  {filter}
                </option>
              ))}
            </select>
          )}

          <select
            // @ts-ignore
            css={selectList}
            value={filterValue('client')}
            onChange={e => {
              gotoPage(0);
              setSortBy([]);
              setFilter('client', e.target.value);
            }}
          >
            <option value={-1}>All Clients</option>
            {clients.map(client => (
              <option key={client.id} value={client.id}>
                {client.company}
              </option>
            ))}
          </select>

          <select
            hidden={filterValue('blackHole') === 'BROAD MATCH QUEUE'}
            // @ts-ignore
            css={selectList}
            value={filterValue('trueNotFounds')}
            onChange={e => {
              gotoPage(0);
              setSortBy([]);
              setFilter('trueNotFounds', e.target.value);
            }}
          >
            {trueNotFoundFilterOptions.map(filter => (
              <option value={filter} key={filter}>
                {filter}
              </option>
            ))}
          </select>

          <select
            // @ts-ignore
            css={selectList}
            value={filterValue('exclusion')}
            onChange={e => {
              gotoPage(0);
              setSortBy([]);
              setFilter('exclusion', e.target.value);
            }}
          >
            {excludesFilterOptions.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
      {documentsQuery.isFetching && (
        <CircularProgress
          size={50}
          // @ts-ignore
          css={progressCircle}
        />
      )}
      <Paper className={classes.root} elevation={3}>
        <TableContainer className={classes.container}>
          <div
            css={{
              display: 'flex',
              justifyContent: 'end',
              backgroundColor: '#fafafa',
              paddingTop: 10,
            }}
          >
            <TextField
              value={searchString}
              disabled={isOcrFilter}
              InputProps={{ disableUnderline: true, className: classes.input }}
              placeholder="Search - Loan Number, Borrower, or Address"
              onChange={e => setSearchString(e.target.value)}
              onKeyDown={e => e.key === 'Enter' && setQuery(prev => ({ ...prev, searchString }))}
              variant="standard"
            />
            <Button css={buttonWrap} onClick={exportData} disabled={isLoading}>
              Export
            </Button>
          </div>
          <Table stickyHeader {...getTableProps()}>
            <TableHead>
              {headerGroups.map(headerGroup => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(col => (
                    <TableCell
                      className={classes.tableCell}
                      {...col.getHeaderProps()}
                      {...(filters.some(
                        ({ value }) => value.startsWith('AGED') || value.startsWith('BROAD'),
                      )
                        ? col.getSortByToggleProps()
                        : null)}
                    >
                      <span
                        css={{ display: 'flex', alignItem: 'center', justifyContent: 'center' }}
                      >
                        <span>{col.render('Header')}</span>
                        {col.isSortedDesc === true ? <UpArrowIcon /> : null}
                        {col.isSortedDesc === false ? <DownArrowIcon /> : null}
                      </span>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              <Fragment>
                {rows.length === 0 && !documentsQuery.isLoading && (
                  <TableRow
                    css={{
                      position: 'absolute',
                      left: (window.innerWidth - 550) / 2,
                    }}
                  >
                    <TableCell css={{ border: 'none !important' }}>
                      <span css={{ fontSize: 18 }}>No results for specified filters.</span>
                    </TableCell>
                  </TableRow>
                )}
                {rows.map(row => {
                  prepareRow(row);
                  return (
                    <Fragment key={row.id}>
                      <TableRow {...row.getRowProps()}>
                        {row.cells.map(cell => (
                          <TableCell
                            className={classes.tableCell}
                            key={cell.column}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </TableCell>
                        ))}
                      </TableRow>
                      {row.isExpanded ? (
                        <TableRow>
                          <TableCell
                            colSpan={visibleColumns.length}
                            className={classes.border}
                            css={{ backgroundColor: '#f5f5f5' }}
                          >
                            {row.original.matchSuggestions.map(ms => (
                              <div key={ms.id}>
                                <div
                                  css={{
                                    display: 'grid',
                                    gridTemplateColumns: ' .7fr 1.3fr .8fr 1.3fr 2fr 2fr 2.2fr',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <span></span>
                                  {/* empty column */}
                                  <Link
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href={`/documents/match?documentId=${ms.documentId}&loanId=${ms.loanId}`}
                                  >
                                    Suggested Match
                                  </Link>
                                  <Link target="_blank" href={`/loans/${ms.loanId}`}>
                                    Loan Info
                                  </Link>
                                  <div css={{ marginLeft: 10 }}>
                                    <h3 css={matchTitles}>Loan Number</h3>
                                    <div css={{ minHeight: 34 }}>{ms.loanNumber}</div>
                                  </div>
                                  <div css={{ marginLeft: 10 }}>
                                    <h3 css={matchTitles}>Client</h3>
                                    <div css={{ minHeight: 34 }}>{ms.client}</div>
                                  </div>
                                  <div css={{ marginLeft: 10 }}>
                                    <h3 css={matchTitles}>Investor</h3>
                                    <div css={{ minHeight: 34 }}>{ms.investor?.name}</div>
                                  </div>
                                  {ms.approvals.find(a => !a.isApproved) && (
                                    <div>
                                      <span
                                        css={{
                                          color: 'white',
                                          backgroundColor: 'red',
                                          borderRadius: 6,
                                          minHeight: 40,
                                          fontSize: 11,
                                          fontWeight: 'bold',
                                          padding: 10,
                                        }}
                                      >
                                        Match Status: Rejected
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </Fragment>
                  );
                })}
              </Fragment>
            </TableBody>
          </Table>
        </TableContainer>
        <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <div
            css={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {totalCount && totalCount > 0 ? (
              <span css={{ marginRight: 20 }}>Total: {totalCount}</span>
            ) : null}
            <span css={{ marginRight: 20 }}>Page: {pageIndex + 1} </span>
            <span>Rows per page: </span>
            <select
              // @ts-ignore
              css={[selectList, { marginLeft: 20 }]}
              onChange={e => setPageSize(e.target.value)}
              value={pageSize}
            >
              {[25, 50, 100, 200].map(num => (
                <option key={num} value={num}>
                  {num}
                </option>
              ))}
            </select>
            <span
              css={[
                arrow,
                (!canPreviousPage || documentsQuery.isFetching) && disabledArrow,
                canPreviousPage && !documentsQuery.isFetching && hoverArrow,
              ]}
              onClick={() => !documentsQuery.isFetching && previousPage()}
            >
              <LeftArrowIcon />
            </span>
            <span
              css={[
                arrow,
                (rows.length < pageSize || documentsQuery.isFetching) && disabledArrow,
                rows.length === pageSize && !documentsQuery.isFetching && hoverArrow,
              ]}
              onClick={() => !documentsQuery.isFetching && nextPage()}
            >
              <RightArrowIcon />
            </span>
          </div>
        </div>
      </Paper>
      {showBroadMatchesModal && (
        <BroadMatchPanel
          showModal={showBroadMatchesModal}
          setShowModal={setShowBroadMatchesModal}
          refresh={refresh}
          documentId={docId}
          extractedInfo={extractedInfo}
        />
      )}
    </Fragment>
  );
}
