/** @jsx jsx */
import { jsx } from '@emotion/core';
import { DocumentStatus } from '../../../pages/CheckUploadedDoc';
import { Fragment } from 'react';
import Message from './Message';
import { IconType } from '../../ui/Icon';

const StatusDisplay = ({ documentStatus }: { documentStatus: DocumentStatus }) => {
  if (!documentStatus.userAlertType) {
    return <Fragment />;
  }

  switch (documentStatus.userAlertType) {
    case 'Check':
      return (
        <div>
          <Message iconType={IconType.StapleSuccessIcon}>{documentStatus.message}</Message>
          <div>{documentStatus.additionalInformation}</div>
        </div>
      );
    case 'X':
      return <Message iconType={IconType.StapleErrorIcon}>{documentStatus.message}</Message>;
    case 'AlertBanner':
      return <Message iconType={IconType.StapleWarningIcon}>{documentStatus.message}</Message>;
    default:
      throw new Error(`There was an Error! No status ${documentStatus.userAlertType} known`);
  }
};

export default StatusDisplay;
